import Vue from "vue";
import Component from "vue-class-component";

@Component
export class CoachAdmin extends Vue {
  public get coachPlayers(): Array<string> | null {
    if (this.$store.getters.featureFlags["coach-players"]) {
      return this.$store.getters.coachPlayers
        .filter((p) => !p.disabled)
        .map((p) => p.uid);
    }
    return null;
  }
}











































































































import CashPaymentButton from "@/components/Payments/CashPaymentButton.vue";
import EditableAmount from "@/components/Payments/EditableAmount.vue";
import PaymentStatus from "@/components/Payments/PaymentStatus.vue";
import { PrivateLessonPaymentViewItem } from "@/types/Payment";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import TimeIcon from "@/assets/icons/saxcons/clock-linear.svg";
import TablePaymentMethod from "@/components/Payments/TablePaymentMethod.vue";
import BackButton from "@/assets/icons/saxcons/back-square-linear.svg";

@Component({
  name: "private-lesson-mobile-payment-item",
  components: {
    BackButton,
    TablePaymentMethod,
    TimeIcon,
    CashPaymentButton,
    EditableAmount,
    PaymentStatus
  }
})
export default class PrivateLessonMobilePaymentItem extends Vue {
  @Prop({ required: true })
  item!: PrivateLessonPaymentViewItem;

  @Prop({ required: false, default: false })
  isParentLoading!: boolean;

  @Prop({ required: true })
  paymentMethodsLoaded!: boolean;

  detailsOpen: number | null = null;

  get showActions(): boolean {
    if (this.item.status === "success") {
      return false;
    } else if (this.item.status === "processing") {
      return false;
    }
    return true;
  }

  get paidMessage(): string | null {
    if (this.item.status === "success") {
      if (this.item.paidInCash) {
        return "Paid by Cash";
      }
      return "Paid Online";
    }
    return null;
  }

  changeAmount(itemId: string, amount: number) {
    this.$emit("amountUpdated", itemId, amount);
  }
  changeCashStatus(uid: string, item: PrivateLessonPaymentViewItem) {
    this.$emit("changeCashStatus", uid, item);
  }
}

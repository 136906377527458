


































import Vue from "vue";
import Component from "vue-class-component";
import LeftIcon from "@/assets/icons/saxcons/chevron-left-linear.svg";
import RightIcon from "@/assets/icons/saxcons/chevron-right-linear.svg";
import { SportangoSelect } from "./overrides";
import dayjs from "dayjs";
import isoWeeksInYear from "dayjs/plugin/isoWeeksInYear";
import isoWeek from "dayjs/plugin/isoWeek";
import isLeapYear from "dayjs/plugin/isLeapYear";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { Prop, VModel } from "vue-property-decorator";
import { AutoCompleteItem } from "./mixins";
dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);
dayjs.extend(isoWeeksInYear);
dayjs.extend(isLeapYear);

@Component({
  name: "sportango-week-picker",
  components: {
    SportangoSelect,
    LeftIcon,
    RightIcon
  }
})
export default class SportangoWeekPicker extends Vue {
  @Prop({ required: false, type: Number, default: 1970 })
  minYear!: number;

  @VModel({ type: [Date] })
  field!: Date;
  selectedYear: number;
  selectedWeek: number;

  constructor() {
    super();
    this.selectedYear = dayjs().year();
    this.selectedWeek = dayjs(this.field).week();
  }

  get weekOptions(): AutoCompleteItem[] {
    const numberOfWeeks = dayjs().year(this.selectedYear).isoWeeksInYear();
    const weeks: number[] = [];
    for (let i = 0; i < numberOfWeeks + 1; i++) {
      weeks.push(i);
    }
    return weeks.map((w) => {
      const rangeOfSelectedWeek = dayjs().year(this.selectedYear).week(w);
      const startOfSelectedWeek = rangeOfSelectedWeek
        .startOf("week")
        .add(1, "day");
      const endOfSelectedWeek = rangeOfSelectedWeek.endOf("week").add(1, "day");
      return {
        value: w,
        text: `${startOfSelectedWeek.format(
          "MMM D"
        )} - ${endOfSelectedWeek.format(
          "MMM D"
        )} (${startOfSelectedWeek.year()})`
      };
    });
  }

  get yearRange(): number[] {
    const result = [];
    for (let index = this.minYear; index <= dayjs().year(); index++) {
      result.push(index);
    }
    return result;
  }

  watchSelectedWeek(newValue: number) {
    const changeCount = newValue - this.selectedWeek;
    for (let index = 0; index < changeCount; index++) {
      this.updateSelected(changeCount > 0 ? "forward" : "backward");
    }
  }

  /** This function is used to change things based on week movement */
  updateSelected(movement: "forward" | "backward") {
    if (movement === "forward") {
      // make changes based on backwards movement
      if (this.selectedWeek === 52) {
        // This is the last week of the year
        this.selectedYear += 1;
        this.selectedWeek = 1;
      } else {
        this.selectedWeek += 1;
      }
    } else {
      // make changes based on backwards movement
      if (this.selectedWeek === 0) {
        // This is the last week of the year
        this.selectedYear -= 1;
        this.selectedWeek = dayjs().year(this.selectedYear).isoWeeksInYear();
      } else {
        this.selectedWeek -= 1;
      }
    }
    // change the value of the field
    this.field = dayjs()
      .year(this.selectedYear)
      .week(this.selectedWeek)
      .startOf("week")
      .add(1, "day")
      .toDate();
  }
}
